<template>
  <div id="success-payment-page">
      <NavPackage/>
      <div class="container-sp">
        <div class="row">
            <div class="col-md-6">
                    <div class="mx-4 px-4 left-sp d-flex justify-content-center">
                        <img src="@/assets/images/failed-payment.png"  alt class="cursor-pointer px-2 img-fluid" />
                    </div>
            </div>
            <div class="col-md-6">
                    <div class="mb-4 pb-4 right-sp">
                        <h1>Payment Rejected</h1><br>
                        <p class="default-text-style mb-0">Unfortunately, we’re unable to process your payment.  Please</p>
                        <p class="default-text-style">try again or contact your payment gateway provider.</p><br>
                        <a href="/" class="btn btn-sp">Dashboard</a>
                    </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import NavPackage from '../../../components/nav-packages.vue'
export default {
  name: 'PaymentFailed',
  components: {
      NavPackage
  },
  mounted() {
    //   this.$ga.page('payment/failed')
      this.$ga.event('payment-failed-page', 'actionx', 'labelx', 1)
  }
}
</script>

<style scoped>
.container-sp{
    padding: 100px 100px;
}
.right-sp{
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.btn-sp{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    border-radius: 4px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    background-color: #00AAFF;
}

</style>